export const TEST_IDS = {
  CARD: 'card',
  CARD_CONTAINER: 'cardContainer',
  QUANTITY_CONTAINER: 'quantityContainer',
  COUNT: 'count',
  IMAGE_CONTAINER: 'imageContainer',
  OVERLAY: 'overaly',
  CONTENT: 'content',
  PRICE: 'price',
  NAME: 'name',
  THCCBD_SUBTEXT: 'thccbdsubtext',
  BRAND: 'brand',
  STRAIN: 'strain',
  AVAILABILITY: 'availability',
  TAG: 'tag'
}

export const testBaseProduct = {
  id: 1,
  name: 'a prerolled thing',
  species: {
    name: 'sativa',
    highCBD: true,
    isAccessory: false
  },
  brand: {
    name: 'justintin'
  },
  photoUrl: '',
  highCBD: true,
  isAccessory: false,
  isAvailable: true,
  tag: {
    text: 'tag1',
    tagType: 'red'
  },
  price: 10,
  cbd: 10,
  thc: 20,
  cbdMeasure: 'test',
  thcMeasure: 'test'
}

export const testBaseCart = {
  products: [
    {
      id: testBaseProduct.id,
      quantity: 2
    }
  ]
}

export const testRouter = {
  asPath: '/article/hippie-sabotage-420-giveaway',
  basePath: '',
  components: {},
  defaultLocale: undefined,
  domainLocales: undefined,
  isFallback: false,
  isLocaleDomain: false,
  isPreview: false,
  isReady: true,
  locale: undefined,
  locales: undefined,
  pathname: '/article/[slug]',
  query: { slug: 'hippie-sabotage-420-giveaway', product_id: '' },
  route: '/article/[slug]',
  events: {}
}

export const testArticle = {
  acf: {
    eaze_draft_status: 'live',
    eaze_group_id: '',
    eaze_group_slug: '',
    eaze_post_featured: 'secondary',
    _article_image_id: 16625
  },
  article_img: 'https://cms-assets.eaze.com/content/2023/03/31192953/Hippie-Sabotage-Blog-Banner-FINAL-1.png',
  author: 71,
  author_meta: { first_name: 'Eaze', last_name: 'Team' },
  categories: [772, 1],
  collection_img: 'https://cms-assets.eaze.com/content/2023/03/31192953/Hippie-Sabotage-Blog-Banner-FINAL-1.png',
  collection_img_thumb: 'https://cms-assets.eaze.com/content/2023/03/31192953/Hippie-Sabotage-Blog-Banner-FINAL-1.png',
  comment_status: 'closed',
  content: {
    protected: false,
    rendered: '<p>🚀HIPPIE SABOTAGE 420 GIVEAWAY🚀 It&#8217;s finally here</p>'
  },
  date: '2023-04-03T09:00:51',
  date_gmt: '2023-04-03T09:00:51',
  eaze_draft_status: 'live',
  eaze_group_id: '',
  eaze_group_slug: '',
  eaze_post_featured: 'secondary',
  excerpt: {
    protected: false,
    rendered: '🚀HIPPIE SABOTAGE 420 GIVEAWAY🚀 It&#8217;s finally here. Our favorite holiday &#8211; 420.'
  },
  excerpt_raw: '',
  featured_media: 16625,
  format: 'standard',
  guid: {
    rendered: 'https://cms.eaze.com/?p=16516'
  },
  html_as_object: null,
  id: 16516,
  link: 'https://cms.eaze.com/hippie-sabotage-420-giveaway',
  meta: [],
  modified: '2023-04-06T17:45:17',
  modified_gmt: '2023-04-06T17:45:17',
  ping_status: 'closed',
  post_thumb: 'https://cms-assets.eaze.com/content/2023/03/31192953/Hippie-Sabotage-Blog-Banner-FINAL-1-70',
  related_posts: [
    {
      id: 16596,
      image: 'https://cms-assets.eaze.com/content/2023/03/06205159/Blog-Banners-Article-Featured-Image-47.png',
      slug: 'top-may-weed-strains-from-eaze-delivery',
      status: 'live',
      title: 'Top May Weed Strains from Eaze Delivery'
    }
  ],
  slug: 'hippie-sabotage-420-giveaway',
  status: 'publish',
  sticky: false,
  tags: [],
  template: '',
  title: {
    rendered: 'Hippie Sabotage 420 Giveaway'
  },
  title_raw: 'Hippie Sabotage 420 Giveaway',
  type: 'post',
  yoast_head: '<!-- This site is optimized with the Yoast SEO plugin v16.8 - https://yoast.com/wordpress/pl',
  yoast_head_json: {
    article_modified_time: '2023-04-06T17:45:17+00:00',
    article_published_time: '2023-04-03T09:00:51+00:00',
    og_description: '🚀HIPPIE SABOTAGE 420 GIVEAWAY🚀 It&#8217;s finally here.',
    og_image: [
      {
        alt: 'Hippie Sabotage Blog Banner',
        height: 628,
        id: 16625,
        path: '/srv/src/web/app/uploads/2023/03/Hippie-Sabotage-Blog-Banner-FINAL-1.png',
        pixels: 753600,
        size: 'full',
        type: 'image/png',
        url: 'https://cms-assets.eaze.com/content/2023/03/31192953/Hippie-Sabotage-Blog-Banner-FINAL-1.png',
        width: 1200
      }
    ],
    og_locale: 'en_US',
    og_site_name: 'Eaze CMS',
    og_title: 'Hippie Sabotage 420 Giveaway - Eaze CMS',
    og_type: 'article',
    og_url: 'https://cms.eaze.com/hippie-sabotage-420-giveaway',
    robots: {},
    schema: {},
    title: '',
    twitter_card: 'summary_large_image',
    twitter_misc: {}
  },
  yoast_metadata: {
    content_score: '30',
    'estimated-reading-time-minutes': '8',
    primary_category: '1',
    metadesc: '',
    title: 'test title'
  }
}

export const testGroup = {
  id: '',
  products: [],
  slug: ''
}

export const testArticleProps = {
  cart: {
    expired: false,
    id: null,
    products: [],
    productsList: [],
    sizeMismatch: false
  },
  catalogItemId: 'test',
  currentArticle: testArticle,
  notFound: false,
  group: testGroup,
  placeId: '4f03a0fe936bba0da38af6a15f77d64740db175c6a3200e68518d46868797adc_eaze',
  products: {},
  router: testRouter,
  title: 'test'
}
