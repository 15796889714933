import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { DeepPartial } from 'redux'

import { fetchRules } from '@/redux/action-wrappers/location'
import { ActiveLocation } from '@/redux/location/reducer'
import { getActiveLocation } from '@/redux/location/selectors'
import { RootState } from '@/redux/reducers'
import { WarningMessage } from '@bff/models/og/rules'
import { EAZE_ORIGIN } from '@helpers/constants'

export default function useFooterRules() {
  const [footerWarningMessages, setFooterWarningMessages] = useState<WarningMessage[]>([])
  const [savedDepotId, setDepotId] = useState<number>()
  const activeLocation = useSelector<RootState, DeepPartial<ActiveLocation>>(getActiveLocation)

  useEffect(() => {
    async function updateFooterMessage() {
      const data = await fetchRules(
        activeLocation.coordinates.latitude,
        activeLocation.coordinates.longitude,
        'footerWarningMessages',
        EAZE_ORIGIN
      )
      setFooterWarningMessages(data.footerWarningMessages)
    }

    if (activeLocation?.depot?.id) {
      if (savedDepotId !== activeLocation.depot.id) {
        setDepotId(activeLocation.depot.id)
        updateFooterMessage()
      }
    }
  }, [savedDepotId, activeLocation])

  return footerWarningMessages
}
